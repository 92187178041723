import React, { FC, Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { color, Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import ImageWithText from '@/components/snippets/ImageWithText'
import ServiceLogo from '@/components/snippets/ServiceLogo'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Points: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="black.0" theme={theme} {...others}>
      <Container
        responsive={{
          default: 1,
          sm: 2
        }}
      >
        <Heading
          sub={{
            text: <Fragment>賃貸管理アシスタント</Fragment>,
            type: 'contained'
          }}
        >
          <Heading_Text>
            <ServiceLogo css={styles.ServiceLogo({ theme })} />
            の3つの特長
          </Heading_Text>
        </Heading>
        <List>
          <List_Item>
            <ImageWithText
              even={false}
              number={1}
              image={
                <StaticImage
                  src="./images/point-1.png"
                  alt=""
                  placeholder="none"
                  quality={100}
                  loading="eager"
                />
              }
              heading={
                <Fragment>
                  厳選された経験豊富な
                  <br />
                  アシスタント
                </Fragment>
              }
              text={
                <Fragment>
                  賃貸管理経験豊富なスタッフを厳選しているため、安心して、簡単にご依頼できます。
                  <br />
                  雇用リスクがゼロになるだけではなく、基本的なビジネススキル教育やモチベーション面などのマネジメントも必要ありません。
                </Fragment>
              }
            />
          </List_Item>
          <List_Item>
            <ImageWithText
              even={true}
              number={2}
              image={
                <StaticImage
                  src="./images/point-2.png"
                  alt=""
                  placeholder="none"
                  quality={100}
                  loading="eager"
                />
              }
              heading={<Fragment>コア業務に集中できる</Fragment>}
              text={
                <Fragment>
                  あなたがコア業務に集中できるよう様々な業務をご依頼いただけます。
                  <br />
                  賃貸仲介、売買、オーナー対応など本当にやるべき仕事に集中いただくために「らくなげアシスタント」が徹底サポート。
                  <br />
                  1か月あたり10時間からスタートできるので、無駄がありません。
                </Fragment>
              }
            />
          </List_Item>
          <List_Item>
            <ImageWithText
              even={false}
              number={3}
              image={
                <StaticImage
                  src="./images/point-3.png"
                  alt=""
                  placeholder="none"
                  quality={100}
                  loading="eager"
                />
              }
              heading={
                <Fragment>
                  募集業務や入力業務や解約業務など幅広い業務に対応
                </Fragment>
              }
              text={
                <Fragment>
                  ”本当にやるべき業務に100%集中したい”そんなあなたを幅広い業務内容を持つ「らくなげ」が徹底サポートいたします。厳選された経験豊富なアシアスタントがチームで対応いたします。
                </Fragment>
              }
            />
          </List_Item>
        </List>
      </Container>
    </Root>
  )
}

export default Points

const Root = styled(Section)<StyleProps>``

const Heading_Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const List = styled.div`
  margin-top: ${rem(48)};
  @media ${mq.and(breakpoints.gt_md)} {
    margin-top: ${rem(64)};
  }
`

const List_Item = styled.div`
  margin-top: ${rem(48)};
  @media ${mq.and(breakpoints.gt_md)} {
    margin-top: ${rem(64)};
  }
`

const styles = {
  ServiceLogo: (props: StyleProps) => css`
    color: ${color('primary')(props)};
    display: inline-block;
    margin-right: ${rem(8)};
    width: ${rem(130)};
    height: ${rem(43.7)};
  `
}
