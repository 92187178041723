import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { rem } from '@/utils/helpers'
import { color, Theme, ColorParams } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  tag?: keyof JSX.IntrinsicElements
  sub?: {
    text: React.ReactNode
    type?: 'outlined' | 'contained'
    color?: ColorParams
  }
  [key: string]: any
  color?: ColorParams
}

interface StyleProps extends Props {
  theme: Theme
}

interface SubProps extends Props {
  theme: Theme
  subcolor?: ColorParams
}

const Heading: FC<Props> = ({
  tag = 'h2',
  sub,
  color,
  children,
  ...others
}) => {
  const { theme } = useContext(ThemeContext)
  const Root = DefaultRoot.withComponent(tag)
  return (
    <Root theme={theme} {...others}>
      {sub && (
        <Sub
          theme={theme}
          type={sub.type}
          subcolor={sub.color}
          component="span"
          variant={sub.type === 'contained' ? 'h4' : 'h3'}
          responsive={{
            sm: sub.type === 'contained' ? 'h6' : 'h5'
          }}
        >
          <span>{sub.text}</span>
        </Sub>
      )}
      <Main
        theme={theme}
        component="span"
        variant="h2"
        color={color}
        responsive={{
          lg: 'h3',
          md: 'h3',
          sm: 'h3'
        }}
      >
        {children}
      </Main>
    </Root>
  )
}

const DefaultRoot = styled.div`
  text-align: center;
`

const Sub = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(12)};

  span {
    ${(props: SubProps) => {
      const key =
        typeof props.subcolor === 'string'
          ? props.subcolor
          : props.subcolor?.key || 'primary'
      const state =
        typeof props.subcolor === 'string' ? undefined : props.subcolor?.state
      switch (props.type) {
        case 'outlined': {
          const isContrast = state === 'contrast'
          const borderColor = color({
            key,
            state: isContrast ? 'contrast' : undefined
          })(props)
          return css`
            display: block;
            padding: ${rem(4)} ${rem(12)};
            border: 1px solid ${borderColor};
            color: ${borderColor};
          `
        }
        case 'contained': {
          const isContrast = state === 'contrast'
          const backgroundColor = color({
            key,
            state: isContrast ? 'contrast' : undefined
          })(props)
          const textColor = color({
            key,
            state: isContrast ? undefined : 'contrast'
          })(props)
          return css`
            display: block;
            padding: ${rem(4)} ${rem(12)};
            background-color: ${backgroundColor};
            color: ${textColor};
          `
        }
        default:
          return null
      }
    }}
  }
`

const Main = styled(Typography)<StyleProps>`
  strong {
    color: ${color('primary')};
  }
`

export default Heading
