import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import { color, Theme } from '@/utils/themes'
import breakpoints from '@/utils/breakpoints'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'
import Tag from '@/components/snippets/Tag'

interface Props {
  title: React.ReactNode
  subtitle?: React.ReactNode
  image: React.ReactNode
  text?: React.ReactNode
  titleTag?: keyof JSX.IntrinsicElements
  tags: string[]
  [key: string]: any
}

interface StyleProps {
  theme: Theme
  [key: string]: any
}

const Card3: FC<Props> = ({
  titleTag,
  title,
  subtitle,
  image,
  text,
  tags,
  ...others
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      <Head>
        <Image>{image}</Image>
      </Head>
      <Main>
        <Title
          variant="subtitle1"
          component={titleTag || 'h4'}
          responsive={{
            md: 'h4',
            sm: 'h5'
          }}
        >
          {title}
        </Title>
        {subtitle && (
          <Subtitle
            theme={theme}
            variant="subtitle1"
            component="h4"
            responsive={{
              md: 'h4',
              sm: 'h5'
            }}
          >
            {subtitle}
          </Subtitle>
        )}
        {text && <Text variant="body1">{text}</Text>}
        <Tags>
          {tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </Tags>
      </Main>
    </Root>
  )
}

Card3.defaultProps = {
  titleTag: 'h4'
}

export default Card3

const Root = styled.div`
  position: relative;
  @media ${mq.and(breakpoints.sm)} {
    display: flex;
  }
`

const Head = styled.div``

const Main = styled.div``

const Image = styled.div`
  width: 100%;
  text-align: center;
  @media ${mq.and(breakpoints.sm)} {
    width: ${rem(80)};
    margin-right: ${rem(12)};
  }
`

const Title = styled(Typography)`
  margin-top: ${rem(24)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: 0;
  }
`

const Subtitle = styled(Typography)<StyleProps>`
  margin-top: ${rem(8)};
  color: ${color('primary')};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: 0;
  }
`

const Text = styled(Typography)`
  margin-top: ${rem(8)};
`

const Tags = styled.div`
  margin-top: ${rem(12)};
  display: flex;
  flex-wrap: wrap;
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(8)};
  }

  > * {
    margin-top: ${rem(8)};
    margin-right: ${rem(8)};
  }
`
