import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { color, Theme } from '@/utils/themes'
import fonts from '@/utils/fonts'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  number: number
  heading: React.ReactNode
  text: React.ReactNode
  image: React.ReactNode
  even: boolean
  [key: string]: any
}

interface StyleProps {
  even?: boolean
  theme: Theme
}

const ImageWithText: FC<Props> = ({
  number,
  heading,
  text,
  image,
  even,
  ...others
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root {...others}>
      <Inner theme={theme} even={even}>
        <Left>
          <Head>
            <Number theme={theme}>{`00${number}`.slice(-2)}</Number>
            <Heading
              variant="h3"
              component="div"
              responsive={{
                md: 'h4',
                sm: 'h4'
              }}
            >
              {heading}
            </Heading>
          </Head>
          <Text variant="body2">{text}</Text>
        </Left>
        <Right>
          <Image>{image}</Image>
        </Right>
      </Inner>
    </Root>
  )
}

ImageWithText.defaultProps = {
  even: false
}

const Root = styled.div``

const Inner = styled.div<StyleProps>`
  @media ${mq.and(breakpoints.gt_sm)} {
    display: flex;
    align-items: center;
    flex-direction: ${({ even }) => (even ? 'row-reverse' : 'row')};
    margin: 0 ${rem(-20)};
  }
  @media ${mq.and(breakpoints.md)} {
    margin: 0 ${rem(-12)};
  }
`

const Left = styled.div`
  @media ${mq.and(breakpoints.gt_sm)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.and(breakpoints.md)} {
    padding: 0 ${rem(12)};
  }
`

const Right = styled.div`
  @media ${mq.and(breakpoints.gt_sm)} {
    flex-shrink: 0;
    flex-grow: 0;
    width: 50%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.and(breakpoints.md)} {
    padding: 0 ${rem(12)};
  }
`

const Head = styled.h3`
  display: flex;
  align-items: center;
`

const Number = styled.div<StyleProps>`
  flex-shrink: 0;
  margin-right: ${rem(20)};
  color: ${color('primary')};
  font-size: ${rem(50)};
  font-weight: bold;
  font-family: ${fonts.en};
  letter-spacing: 0.04em;
  @media ${mq.and(breakpoints.md)} {
    margin-right: ${rem(16)};
    font-size: ${rem(36)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-right: ${rem(16)};
    font-size: ${rem(40)};
  }
`

const Heading = styled(Typography)``

const Text = styled(Typography)`
  margin-top: ${rem(24)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(16)};
  }
`

const Image = styled.div`
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(16)};
  }
`

export default ImageWithText
