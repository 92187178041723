import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import fonts from '@/utils/fonts'
import { color, Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  title: React.ReactNode
  image: React.ReactNode
  text: React.ReactNode
  color: string
  [key: string]: any
}

interface StyleProps {
  theme: Theme
  color: string
  [key: string]: any
}

const Card2: FC<Props> = ({ title, image, text, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      <Title
        variant="h3"
        component="h4"
        responsive={{
          md: 'h4',
          sm: 'h4'
        }}
      >
        {title}
      </Title>
      <Image>{image}</Image>
      <Text
        color={others.color}
        theme={theme}
        variant="subtitle1"
        responsive={{
          sm: 'h6'
        }}
      >
        <span>{text}</span>
      </Text>
    </Root>
  )
}

const Root = styled.div<StyleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  color: ${color()};
  background-color: ${color('black.0')};
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(20)};
  }
`

const Image = styled.div`
  flex-grow: 0;
  margin-top: ${rem(10)};
  width: 100%;
  text-align: center;
`

const Title = styled(Typography)`
  flex-grow: 0;
  font-family: ${fonts.en};
`

const Text = styled(Typography)<StyleProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${rem(10)};
  text-align: center;
  color: ${color('black.100')};
  @media ${mq.and(breakpoints.sm)} {
    text-align: left;
  }

  strong {
    color: ${color()};
  }
`

export default Card2
