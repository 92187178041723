import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { rem } from '@/utils/helpers'
import { Theme, color } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  color?: string
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Tag: FC<Props> = ({ children, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root
      variant="caption"
      responsive={{
        sm: 'overline'
      }}
      theme={theme}
      {...others}
    >
      {children}
    </Root>
  )
}

Tag.defaultProps = {
  color: 'black.5'
}

const Root = styled(Typography)<StyleProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(4)} ${rem(12)};
  border-radius: ${rem(100)};
  background-color: ${color()};
  color: ${color({ state: 'contrast' })};
`

export default Tag
