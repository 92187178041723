import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { Theme, color } from '@/utils/themes'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'

interface Props {
  [key: string]: any
}

interface StyleProps {
  theme: Theme
  [key: string]: any
}

const Company: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="black.5" theme={theme} {...others}>
      <Container
        responsive={{
          default: 2,
          sm: 2
        }}
      >
        <Heading>会社概要</Heading>
        <Content theme={theme}>
          <table>
            <tbody>
              <tr>
                <th>会社名</th>
                <td>株式会社らくなげ</td>
              </tr>
              <tr>
                <th>設立</th>
                <td>2020年8月</td>
              </tr>
              <tr>
                <th>代表者</th>
                <td>新元 翔大</td>
              </tr>
              <tr>
                <th>本社</th>
                <td>〒160-0023 東京都新宿区西新宿2丁目6−1 24F</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>66,582,500（2022年1月31日時点）</td>
              </tr>
              <tr>
                <th>事業内容</th>
                <td>賃貸管理BPO「らくなげ」</td>
              </tr>
            </tbody>
          </table>
        </Content>
      </Container>
    </Root>
  )
}

export default Company

const Root = styled(Section)``

const Content = styled.div<StyleProps>`
  margin-top: ${rem(54)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(40)};
  }

  table {
    width: 100%;
  }

  th,
  td {
    padding: ${rem(24)};
    font-size: ${rem(16)};
    line-height: 1.5;
    border-bottom: 1px solid ${color('black.5')};
    @media ${mq.and(breakpoints.sm)} {
      font-size: ${rem(14)};
      padding: ${rem(12)};
    }
  }

  th {
    white-space: nowrap;
    text-align: left;
    background-color: ${color('primary.10')};
    font-weight: bold;
  }

  td {
    background-color: ${color('black.0')};
  }
`
