import React, { FC, Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import ChatBubble from '@/components/snippets/ChatBubble'

interface Props {
  [key: string]: any
}

const Example: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="black.0" theme={theme} {...others}>
      <Container
        responsive={{
          default: 2,
          sm: 2
        }}
      >
        <Heading>依頼のイメージ</Heading>
        <List>
          <List_Item>
            <ChatBubble
              image={{
                content: (
                  <StaticImage
                    src="./images/bubble-client.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                ),
                name: '管理会社'
              }}
              text={
                <Fragment>
                  Aマンション101号室の<strong>募集</strong>をお願いします。
                  <br />
                  <strong>家賃査定</strong>からお願いします。
                </Fragment>
              }
              position="left"
            />
          </List_Item>
          <List_Item>
            <ChatBubble
              image={{
                content: (
                  <StaticImage
                    src="./images/bubble-raqnage.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                )
              }}
              text={
                <Fragment>
                  ご依頼ありがとうございます。
                  <br />
                  Aマンションの101号室ですね。
                  <br />
                  かしこまりました。
                  <br />
                  まずは家賃査定から対応させていただきます。
                  <br />
                  <strong>想定時間は30分</strong>ほど見込んでおります。
                  <br />
                  <strong>明日の18時までに提出</strong>いたします。
                  <br />
                  すぐ<strong>各媒体への掲載を開始</strong>いたします。
                </Fragment>
              }
              position="right"
            />
          </List_Item>
          <List_Item>
            <ChatBubble
              image={{
                content: (
                  <StaticImage
                    src="./images/bubble-client.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                ),
                name: '管理会社'
              }}
              text={
                <Fragment>
                  ありがとうございます。
                  <br />
                  進め方スケジュール問題ありません。
                </Fragment>
              }
              position="left"
            />
          </List_Item>
          <List_Item>
            <ChatBubble
              image={{
                content: (
                  <StaticImage
                    src="./images/bubble-raqnage.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                )
              }}
              text={
                <Fragment>
                  ご確認ありがとうございます。
                  <br />
                  それでは募集業務を承りました。
                </Fragment>
              }
              position="right"
            />
          </List_Item>
        </List>
      </Container>
    </Root>
  )
}

export default Example

const Root = styled(Section)``

const List = styled.div`
  margin-top: ${rem(54)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(40)};
  }
`

const List_Item = styled.div`
  margin-top: ${rem(40)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`
