import React, { FC, Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { color, Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import Card1 from '@/components/snippets/Card1'
import ServiceLogo from '@/components/snippets/ServiceLogo'
import Typography from '@/components/typography'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

const Issues: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root color="primary.10" theme={theme} {...others}>
      <Container
        responsive={{
          default: 1,
          md: 3,
          sm: 2
        }}
      >
        <Heading
          sub={{
            text: <Fragment>賃貸管理業務は膨大...</Fragment>
          }}
        >
          こんなことで
          <br className="v-sm" />
          <strong>悩んで</strong>いませんか？
        </Heading>
        <List>
          <List_Inner>
            <List_Item>
              <Card1
                number={1}
                image={
                  <StaticImage
                    src="./images/issue-1.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                }
                heading={<Fragment>業務が多すぎて仕事が回らない</Fragment>}
                text={
                  <Fragment>
                    毎日発生する細かな事務作業に時間を取られてしまい本業に集中できない...
                  </Fragment>
                }
              />
            </List_Item>
            <List_Item>
              <Card1
                number={2}
                image={
                  <StaticImage
                    src="./images/issue-2.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                }
                heading={
                  <Fragment>人材がいない、マネージャークラスがいない</Fragment>
                }
                text={
                  <Fragment>
                    良い社員が入らない、残っていかない、教える時間がない...
                  </Fragment>
                }
              />
            </List_Item>
            <List_Item>
              <Card1
                number={3}
                image={
                  <StaticImage
                    src="./images/issue-3.png"
                    alt=""
                    placeholder="none"
                    quality={100}
                    loading="eager"
                  />
                }
                heading={
                  <Fragment>
                    効率化したいけどどこから手をつけて良いかわからない
                  </Fragment>
                }
                text={
                  <Fragment>
                    賃貸管理のシステムはたくさんあるが導入のコストが高い...
                  </Fragment>
                }
              />
            </List_Item>
          </List_Inner>
        </List>
      </Container>
      <Solution theme={theme}>
        <Solution_Container
          responsive={{
            default: 2,
            md: 1,
            sm: 1
          }}
        >
          <Solution_Left>
            <Solution_Left_Container>
              <Solution_Subtitle
                variant="h1"
                component="p"
                responsive={{
                  md: 'h2',
                  sm: 'h3'
                }}
              >
                <ServiceLogo css={styles.ServiceLogo({ theme })} />
                で解決！
              </Solution_Subtitle>
              <Solution_Text
                theme={theme}
                variant="h3"
                component="p"
                responsive={{
                  md: 'h5',
                  sm: 'h5'
                }}
              >
                今までやっていた
                <strong>面倒くさい業務</strong>を<br />
                経験豊富な<strong>アシスタント</strong>に外注！
                <br />
                <strong>採用・退職リスクを減らして</strong>
                <br />
                <strong>コア業務</strong>に集中！
              </Solution_Text>
            </Solution_Left_Container>
          </Solution_Left>
          <Solution_Right>
            <StaticImage
              src="./images/solution.png"
              alt=""
              placeholder="none"
              quality={100}
              loading="eager"
            />
          </Solution_Right>
        </Solution_Container>
      </Solution>
    </Root>
  )
}

export default Issues

const Root = styled(Section)<StyleProps>`
  padding-top: ${rem(32)} !important;
  padding-bottom: 0 !important;
  @media ${mq.and(breakpoints.sm)} {
    padding-top: 0 !important;
  }
`

const List = styled.div`
  margin-top: ${rem(64)};
  @media ${mq.with(breakpoints.sm)} {
    margin-top: ${rem(40)};
  }
`

const List_Inner = styled.ol`
  @media ${mq.and(breakpoints.gt_md)} {
    display: flex;
    margin: 0 ${rem(-20)};
  }
  @media ${mq.and(breakpoints.sm)} {
  }
`

const List_Item = styled.li`
  margin-top: ${rem(64)};
  @media ${mq.and(breakpoints.gt_md)} {
    margin-top: 0;
    width: 33.333333%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.with(breakpoints.sm)} {
    margin-top: ${rem(48)};
  }
`

const Solution = styled.div<StyleProps>`
  margin-top: ${rem(64)};
  padding: ${rem(80)} 0;
  background-color: ${color('black.2')};
  color: ${color({ key: 'black.2', state: 'contrast' })};
  @media ${mq.and(breakpoints.md)} {
    padding: ${rem(64)} 0;
  }
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(48)} 0;
  }
`

const Solution_Container = styled(Container)`
  @media ${mq.and(breakpoints.gt_sm)} {
    display: flex;
    align-items: center;
  }
`

const Solution_Left = styled.div`
  @media ${mq.and(breakpoints.gt_sm)} {
    width: 50%;
    flex-shrink: 0;
    padding-right: ${rem(24)};
  }

  @media ${mq.and(breakpoints.md)} {
    display: flex;
    justify-content: center;
  }
`

const Solution_Left_Container = styled.div``

const Solution_Right = styled.div`
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`

const Solution_Subtitle = styled(Typography)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media ${mq.and(breakpoints.sm)} {
    justify-content: center;
  }
`

const Solution_Text = styled(Typography)`
  margin-top: ${rem(24)};
  line-height: 1.75;

  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    line-height: 1.75;
  }

  @media ${mq.and(breakpoints.sm)} {
    text-align: center;
    margin-top: ${rem(16)};
  }

  strong {
    color: ${color('primary')};
  }
`

const styles = {
  ServiceLogo: (props: StyleProps) => css`
    color: ${color('primary')(props)};
    display: inline-block;
    margin-right: ${rem(8)};
    width: ${rem(233)};
    height: ${rem(75)};

    @media ${mq.and(breakpoints.md)} {
      width: ${rem(180)};
      height: ${rem(58)};
    }

    @media ${mq.and(breakpoints.sm)} {
      width: ${rem(160)};
      height: ${rem(51)};
    }

    svg {
      width: 100%;
      height: 100%;
    }
  `
}
