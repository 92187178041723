import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { color, ColorParams } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'
import Button from '@/components/snippets/Button'

interface Props {
  heading: React.ReactNode
  image?: React.ReactNode
  bubble?: React.ReactNode
  items: React.ReactNode[]
  link: {
    to?: string
    href?: string
    text: React.ReactNode
  }
  [key: string]: any
  buttoncolor?: ColorParams
}

const Card5: FC<Props> = ({
  heading,
  image,
  bubble,
  items,
  link,
  buttoncolor,
  ...others
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      {bubble && <Bubble>{bubble}</Bubble>}
      <Heading theme={theme} variant="h3">
        {heading}
      </Heading>
      <Main>
        {image && <Image>{image}</Image>}
        <List>
          {items.map((text, index) => (
            <Item key={index} variant="body2" component="li">
              {text}
            </Item>
          ))}
        </List>
      </Main>
      <Link>
        <Button
          to={link.to}
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          iconAfter="right"
          width="fill"
          color={buttoncolor}
        >
          {link.text}
        </Button>
      </Link>
    </Root>
  )
}

Card5.defaultProps = {
  buttoncolor: 'primary'
}

const Root = styled.div`
  position: relative;
  padding: ${rem(40)};
  background-color: ${color('black.0')};
  @media ${mq.and(breakpoints.md)} {
    padding: ${rem(24)};
  }
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(20)};
  }
`

const Bubble = styled.div`
  position: absolute;
  top: 0;
  right: ${rem(60)};
  transform: translateY(-33%);
  width: ${rem(108)};
  height: ${rem(108)};

  @media ${mq.and(breakpoints.sm)} {
    width: ${rem(80)};
    height: ${rem(80)};
    right: ${rem(0)};
  }
`

const Heading = styled(Typography)`
  padding-bottom: ${rem(8)};
  text-align: center;
  border-bottom: 1px solid ${color('black.10')};
`

const Main = styled.div`
  margin-top: ${rem(24)};
  @media ${mq.and(breakpoints.gt_md)} {
    display: flex;
  }
`

const Image = styled.div`
  max-width: ${rem(100)};
  @media ${mq.and(breakpoints.gt_md)} {
    margin-right: ${rem(16)};
  }
  @media ${mq.and(breakpoints.lg)} {
    max-width: ${rem(80)};
  }
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    margin: 0 auto;
  }
`

const List = styled.ol`
  counter-reset: item;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`

const Item = styled(Typography)`
  margin-top: ${rem(10)};
  display: flex;
  align-items: flex-start;
  &:first-of-type {
    margin-top: 0;
  }
  &::before {
    counter-increment: item;
    content: counter(item);
    font-size: ${rem(14)};
    font-weight: bold;
    margin-top: ${rem(2)};
    margin-right: ${rem(8)};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem(24)};
    height: ${rem(24)};
    border: 1px solid currentColor;
  }
`

const Link = styled.div`
  margin-top: ${rem(24)};
`

export default Card5
