import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import { breakpoints } from '@/utils/preset'
import { Theme, color, ColorParams } from '@/utils/themes'
import zIndex from '@/utils/z-index'
import CSSEase from '@/utils/easing'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'
import Button from '@/components/snippets/Button'

interface Props {
  show: boolean
  heading: React.ReactNode
  buttonText: React.ReactNode
  color?: ColorParams
  icon?: string
  [key: string]: any
}

interface StyleProps {
  theme: Theme
  color?: ColorParams
  [key: string]: any
}

const FloatingCTA: FC<Props> = ({ show, heading, buttonText, color, icon }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} show={show} _color={color}>
      <Head variant="h5" theme={theme} color={color}>
        {heading}
      </Head>
      <CTAButton
        size="small"
        width="fit"
        to="/#contact"
        iconBefore={icon || 'chat-fill'}
        color={color}
      >
        {buttonText}
      </CTAButton>
    </Root>
  )
}

const Root = styled.div<StyleProps>`
  position: fixed;
  z-index: ${zIndex.floating.base};
  right: ${rem(20)};
  bottom: ${rem(20)};
  display: inline-block;
  padding: ${rem(24)} ${rem(16)};
  text-align: center;
  border-radius: ${rem(8)};
  border: ${rem(4)} solid ${color('black.20')};
  background-color: ${color('black.0')};
  transition: transform 0.6s ${CSSEase.easeInOutCubic},
    opacity 0.6s ${CSSEase.easeInOutCubic};
  opacity: 0;
  transform: translateY(100%);
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};

  @media ${mq.and(breakpoints.sm)} {
    display: block;
    padding: ${rem(8)} ${rem(16)};
    left: ${rem(20)};
  }
`

const Head = styled(Typography)`
  padding-bottom: ${rem(8)};
  border-bottom: 1px solid ${color('black.10')};
  color: ${(props) => {
    if (props.color) {
      return color()(props)
    }
    return color('primary')
  }};
`

const CTAButton = styled(Button)`
  margin-top: ${rem(8)};
  @media ${mq.and(breakpoints.sm)} {
    width: 100%;
  }
`

export default FloatingCTA
