import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
// import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import fonts from '@/utils/fonts'
import { Theme, color } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  titleTag?: keyof JSX.IntrinsicElements
  number: {
    value: number
    caption: React.ReactNode
  }
  heading: React.ReactNode
  text: React.ReactNode
  [key: string]: any
}

interface StyleProps {
  theme: Theme
}

const Card4: FC<Props> = ({ titleTag, number, heading, text, ...others }) => {
  const { theme } = useContext(ThemeContext)
  const Head = titleTag ? DefaultHead.withComponent(titleTag) : DefaultHead
  return (
    <Root theme={theme} {...others}>
      <Head>
        <Number theme={theme}>
          <Value>{`00${number.value}`.slice(-2)}</Value>
          <Caption>{number.caption}</Caption>
        </Number>
        <Heading
          variant="subtitle1"
          component="div"
          responsive={{
            md: 'h4'
          }}
        >
          {heading}
        </Heading>
      </Head>
      <Text theme={theme} variant="body2">
        {text}
      </Text>
    </Root>
  )
}

export default Card4

const Root = styled.div`
  padding: ${rem(24)};
  background-color: ${color('black.0')};
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(20)};
  }
`

const DefaultHead = styled.h4`
  @media ${mq.and(breakpoints.sm)} {
    display: flex;
    align-items: center;
  }
`

const Number = styled.div<StyleProps>`
  color: ${color('primary')};
  text-align: center;
  @media ${mq.and(breakpoints.sm)} {
    flex-shrink: 0;
  }
`

const Value = styled.div`
  flex-shrink: 0;
  font-size: ${rem(50)};
  font-weight: bold;
  font-family: ${fonts.en};
  letter-spacing: 0.04em;
  @media ${mq.and(breakpoints.md)} {
    font-size: ${rem(36)};
  }
  @media ${mq.and(breakpoints.sm)} {
    font-size: ${rem(32)};
  }
`

const Caption = styled.div`
  letter-spacing: 0.04em;
  font-size: ${rem(14)};
  @media ${mq.and(breakpoints.sm)} {
    font-size: ${rem(12)};
  }
`

const Heading = styled(Typography)`
  margin-top: ${rem(24)};
  text-align: center;
  @media ${mq.and(breakpoints.sm)} {
    margin-top: 0;
    margin-left: ${rem(12)};
    display: flex;
    align-items: center;
    text-align: left;
  }
`

const Text = styled(Typography)`
  margin-top: ${rem(8)};
  padding-top: ${rem(8)};
  border-top: 1px solid ${color('black.10')};
`
