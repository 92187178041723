import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { Theme, color } from '@/utils/themes'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import withContext from '@/context/withContext'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'
import Card5 from '@/components/snippets/Card5'
import FloatingCTA from '@/components/snippets/FloatingCTA'
import Typography from '@/components/typography'

interface Props {
  theme?: Theme
  [key: string]: any
}

interface State {
  showFloating: boolean
}

interface StyleProps {
  theme?: Theme
}

class CTA extends React.Component<Props, State> {
  private rootRef = React.createRef<HTMLInputElement>()
  private observer: IntersectionObserver | undefined
  constructor(props: Props) {
    super(props)
    this.state = {
      showFloating: false
    }
  }
  componentDidMount() {
    if (!this.rootRef.current) return
    this.observer = new window.IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: null,
        rootMargin: '0px 0px',
        threshold: 0
      }
    )
    this.observer.observe(this.rootRef.current)
    const kv = document.getElementById('kv')
    if (!kv) return
    this.observer.observe(kv)
  }
  componentWillUnmount() {
    this.observer?.disconnect()
  }
  handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({
          showFloating: false
        })
      } else {
        this.setState({
          showFloating: true
        })
      }
    })
  }
  render() {
    const { themeContext, ...others } = this.props
    return (
      <Root theme={themeContext.theme} ref={this.rootRef} {...others}>
        <Section color="primary.100" theme={themeContext.theme} stripe>
          <Container
            responsive={{
              default: 1,
              md: 3,
              sm: 2
            }}
          >
            <Heading
              color={{
                key: 'primary',
                state: 'contrast'
              }}
              sub={{
                text: <Fragment>まずは無料で相談</Fragment>,
                type: 'contained',
                color: {
                  key: 'primary',
                  state: 'contrast'
                }
              }}
            >
              お問い合わせはこちら
            </Heading>
            <List>
              <List_Inner>
                <List_Item>
                  <Card5
                    heading={<Fragment>LINEの場合</Fragment>}
                    image={
                      <StaticImage
                        src="./images/line-qr-v2.png"
                        alt=""
                        placeholder="none"
                        quality={100}
                        loading="eager"
                      />
                    }
                    items={[
                      <Fragment key="1">らくなげ公式LINEの友だち追加</Fragment>,
                      <Fragment key="2">
                        メニューから【ご相談】をタップ
                      </Fragment>,
                      <Fragment key="3">
                        初回打合せ後に、らくなげ業務開始
                      </Fragment>
                    ]}
                    link={{
                      href: 'https://lin.ee/1fRZpj1',
                      text: <Fragment>友達に追加</Fragment>
                    }}
                    buttoncolor="line"
                  />
                </List_Item>
                <List_Item>
                  <Card5
                    heading={<Fragment>フォームの場合</Fragment>}
                    items={[
                      <Fragment key="1">
                        フォームの質問事項にお答えください。
                      </Fragment>,
                      <Fragment key="2">
                        担当よりメールで返信いたします。
                      </Fragment>,
                      <Fragment key="3">
                        初回打合せ後に、らくなげ業務開始
                      </Fragment>
                    ]}
                    link={{
                      href: 'https://forms.gle/JfeEwDe6tq5Nedqy6',
                      text: <Fragment>フォームを開く</Fragment>
                    }}
                  />
                </List_Item>
              </List_Inner>
            </List>
            <Privacy
              variant="body2"
              theme={themeContext.theme}
              responsive={{
                sm: 'caption'
              }}
            >
              当社
              <a href="/privacy" target="_blank">
                プライバシーポリシー
              </a>
              に同意の上、
              <br className="v-sm" />
              お問い合わせください。
            </Privacy>
          </Container>
        </Section>
        <FloatingCTA
          show={this.state.showFloating}
          heading={<Fragment>まずは無料で相談</Fragment>}
          buttonText={<Fragment>お問い合わせ</Fragment>}
        />
      </Root>
    )
  }
}

export default withContext('themeContext', ThemeContext, CTA)

const Root = styled.div<Props>``

const List = styled.div`
  margin-top: ${rem(40)};
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
`

const List_Inner = styled.div`
  @media ${mq.and(breakpoints.gt_md)} {
    display: flex;
    flex-wrap: wrap;
    margin: ${rem(-40)} ${rem(-20)} 0;
  }
`

const List_Item = styled.div`
  @media ${mq.and(breakpoints.gt_md)} {
    margin-top: ${rem(40)};
    width: 50%;
    padding: 0 ${rem(20)};
  }
  @media ${mq.and(breakpoints.md)} {
    margin-top: ${rem(40)};
    &:first-of-type {
      margin-top: 0;
    }
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(40)};
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Privacy = styled(Typography)<StyleProps>`
  text-align: center;
  margin-top: ${rem(24)};
  color: ${color({ key: 'primary', state: 'contrast' })};

  a {
    text-decoration: underline;
  }
`
