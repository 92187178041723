import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { Theme, color } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

type Position = 'left' | 'right'

interface Props {
  image: {
    content: React.ReactNode
    name?: string
  }
  text: React.ReactNode
  position?: Position
}

interface StyleProps {
  position?: Position
  theme: Theme
}

const ChatBubble: FC<Props> = ({ image, text, position, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} position={position} {...others}>
      <Image>
        {image.content}
        <Image_Name
          variant="h5"
          responsive={{
            sm: 'h6'
          }}
        >
          {image.name}
        </Image_Name>
      </Image>
      <Text
        theme={theme}
        position={position}
        variant="h5"
        component="p"
        responsive={{
          sm: 'caption'
        }}
      >
        <span></span>
        {text}
      </Text>
    </Root>
  )
}

ChatBubble.defaultProps = {
  position: 'left'
}

const Root = styled.div<StyleProps>`
  display: flex;
  align-items: flex-start;
  ${(props) =>
    props.position === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`

const Image = styled.div`
  flex-shrink: 0;
  width: ${rem(117)};
  @media ${mq.and(breakpoints.sm)} {
    width: ${rem(64)};
  }
`

const Image_Name = styled(Typography)`
  margin-top: ${rem(10)};
  text-align: center;
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(6)};
  }
`

const Text = styled(Typography)<StyleProps>`
  position: relative;
  ${(props) =>
    props.position === 'left'
      ? css`
          margin-left: ${rem(32)};
        `
      : css`
          margin-right: ${rem(32)};
        `}
  width: 0;
  flex-grow: 1;
  text-align: center;
  padding: ${rem(36)};
  background-color: ${color('black.0')};
  border: ${rem(4)} solid ${color('black.20')};
  border-radius: ${rem(8)};
  @media ${mq.and(breakpoints.sm)} {
    padding: ${rem(16)};
    text-align: left;
    font-weight: bold;
    br {
      display: none;
    }
  }
  strong {
    color: ${color('primary')};
  }
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    ${(props) =>
      props.position === 'left'
        ? css`
            border-left: ${rem(10)} solid transparent;
            border-right: ${rem(10)} solid ${color('black.20')(props)};
            border-top: ${rem(10)} solid transparent;
            border-bottom: ${rem(10)} solid ${color('black.20')(props)};
            left: ${rem(-21)};
            top: ${rem(24)};
            @media ${mq.and(breakpoints.sm)} {
              top: ${rem(8)};
            }
          `
        : css`
            border-left: ${rem(10)} solid ${color('black.20')(props)};
            border-right: ${rem(10)} solid transparent;
            border-top: ${rem(10)} solid transparent;
            border-bottom: ${rem(10)} solid ${color('black.20')(props)};
            right: ${rem(-21)};
            top: ${rem(24)};
            @media ${mq.and(breakpoints.sm)} {
              top: ${rem(8)};
            }
          `}
  }
  &::after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    ${(props) =>
      props.position === 'left'
        ? css`
            border-left: ${rem(7)} solid transparent;
            border-right: ${rem(7)} solid ${color('black.0')(props)};
            border-top: ${rem(7)} solid transparent;
            border-bottom: ${rem(7)} solid ${color('black.0')(props)};
            left: ${rem(-11)};
            top: ${rem(26)};
            @media ${mq.and(breakpoints.sm)} {
              top: ${rem(10)};
            }
          `
        : css`
            border-left: ${rem(7)} solid ${color('black.0')(props)};
            border-right: ${rem(7)} solid transparent;
            border-top: ${rem(7)} solid transparent;
            border-bottom: ${rem(7)} solid ${color('black.0')(props)};
            right: ${rem(-11)};
            top: ${rem(26)};
            @media ${mq.and(breakpoints.sm)} {
              top: ${rem(10)};
            }
          `}
  }
`

export default ChatBubble
