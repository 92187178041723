import React, { FC, useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { Link } from 'gatsby'
import styled from '@emotion/styled/'
import ThemeContext from '@/context/Theme'
import { mq, rem } from '@/utils/helpers'
import { breakpoints } from '@/utils/preset'
import _Container from '@/components/layouts/Container'
import ServiceLogo from '@/components/snippets/ServiceLogo'
import Typography from '@/components/typography'
import Button from '@/components/snippets/Button'
import { Theme, color } from '@/utils/themes'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme?: Theme
}

const KV: FC<Props> = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...props} id="kv">
      <Container
        responsive={{
          default: 1,
          md: 2,
          sm: 2
        }}
      >
        <Head>
          <Logo>
            <ServiceLogo />
          </Logo>
          <Label theme={theme} variant="body1" responsive={{ sm: 'body2' }}>
            不動産会社向け
          </Label>
        </Head>
        <Title
          theme={theme}
          variant="h1"
          responsive={{
            lg: 'h2',
            md: 'h2',
            sm: 'h3'
          }}
        >
          経験豊富な
          <br />
          <strong>賃貸管理アシスタント</strong>が
          <br />
          その業務を徹底サポート
        </Title>
        <Text
          variant="body1"
          responsive={{
            lg: 'body1',
            md: 'body1',
            sm: 'body2'
          }}
        >
          募集、解約、入力、さまざまな業務を優秀なアシスタントに簡単に外注！
        </Text>
        <Image>
          <StaticImage
            src="./images/kv.png"
            alt=""
            placeholder="none"
            quality={100}
            loading="eager"
          />
        </Image>
        <CTA>
          <CTAButton to="#contact" iconAfter="down">
            お問い合わせはこちら
          </CTAButton>
          {/* <CTAText theme={theme} to="/" component={Link} variant="body1">
            賃貸管理会社様はこちら
          </CTAText> */}
        </CTA>
      </Container>
    </Root>
  )
}

export default KV

const Root = styled.div<StyleProps>`
  position: relative;
  background: linear-gradient(90deg, #ffffff 0%, #f1f1f1 100%);
  padding: ${rem(105)} 0;
  overflow: hidden;
  @media ${mq.with(breakpoints.md)} {
    padding: ${rem(60)} 0;
  }
  @media ${mq.with(breakpoints.sm)} {
    padding: ${rem(40)} 0 ${rem(48)};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: ${rem(80)};
    background-color: ${color('primary.10')};
    @media ${mq.with(breakpoints.md)} {
      height: ${rem(240)};
    }
    @media ${mq.with(breakpoints.sm)} {
      height: ${rem(240)};
    }
  }
`

const Container = styled(_Container)`
  position: relative;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`

const Label = styled(Typography)<StyleProps>`
  margin-left: ${rem(24)};
  padding: 0 ${rem(16)};
  border-radius: ${rem(100)};
  border: 1px solid ${color('primary')};
  color: ${color('primary')};

  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    margin-left: 0;
    margin-bottom: ${rem(16)};
  }
`

const Logo = styled.div`
  display: block;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    text-align: center;
  }
  svg {
    width: ${rem(180)};
    height: ${rem(60.5)};

    @media ${mq.and(breakpoints.sm)} {
      width: ${rem(160)};
      height: ${rem(53.7)};
    }
  }
`

const Title = styled(Typography)<StyleProps>`
  margin-top: ${rem(24)};
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    position: relative;
    text-align: center;
  }
  strong {
    color: ${color('primary')};
  }
`

const Text = styled(Typography)`
  margin-top: ${rem(40)};
  max-width: ${rem(450)};
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    position: relative;
    margin-top: ${rem(24)};
    max-width: none;
    text-align: center;
  }
  @media ${mq.with(breakpoints.sm)} {
    br {
      display: none;
    }
  }
`

const Image = styled.div`
  @media ${mq.with(breakpoints.gt_md)} {
    position: absolute;
    left: 48%;
    width: 50%;
    bottom: ${rem(-25)};
    min-width: ${rem(698)};
  }
  @media ${mq.with(breakpoints.lg)} {
    min-width: 0;
  }
  @media ${mq.with(breakpoints.md)} {
    margin: ${rem(24)} auto 0;
    max-width: ${rem(480)};
  }
  @media ${mq.with(breakpoints.sm)} {
    margin: ${rem(24)} auto 0;
  }
`

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    align-items: center;
    text-align: center;
  }
`

const CTAButton = styled(Button)`
  margin-top: ${rem(40)};
`

// const CTAText = styled(Typography)<StyleProps>`
//   margin-top: ${rem(16)};
//   font-weight: bold;
//   color: ${color('primary')};
//   text-decoration: underline;
// `
