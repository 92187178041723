import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import { breakpoints } from '@/utils/preset'
import fonts from '@/utils/fonts'
import { color, Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Typography from '@/components/typography'

interface Props {
  number: number
  image: React.ReactNode
  heading: React.ReactNode
  text: React.ReactNode
  [key: string]: any
}

interface StyleProps {
  theme: Theme
  [key: string]: any
}

const Card1: FC<Props> = ({ image, heading, text, ...others }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Root theme={theme} {...others}>
      <Image>{image}</Image>
      <Heading variant="subtitle1" component="h3">
        {heading}
      </Heading>
      <Text variant="body2">{text}</Text>
    </Root>
  )
}

const Root = styled.div<StyleProps>`
  position: relative;
  &::before {
    content: '${(props) => `00${props.number}`.slice(-2)}';
    position: absolute;
    top: 0;
    left: 0;
    font-family: ${fonts.en};
    font-size: ${rem(64)};
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.3;
    color: ${color('primary')};
    transform: translateY(-50%);
    z-index: 2;
    @media ${mq.with(breakpoints.sm)} {
      font-size: ${rem(48)};
      transform: translateY(-10%);
    }
  }
`

const Image = styled.div``

const Heading = styled(Typography)`
  margin-top: ${rem(16)};
`

const Text = styled(Typography)`
  margin-top: ${rem(8)};
`

export default Card1
