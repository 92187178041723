import React from 'react'
import ThemeContext, { ThemeContextType } from '@/context/Theme'
import styled from '@emotion/styled'
import themes from '@/utils/themes'
import withContext from '@/context/withContext'
import SEO from '@/components/head/SEO'
import KV from '@/components/sections/KV'
import Issues from '@/components/sections/Issues'
import Points from '@/components/sections/Points'
import Case from '@/components/sections/Case'
import Example from '@/components/sections/Example'
import Pricing from '@/components/sections/Pricing'
import Flow from '@/components/sections/Flow'
import CTA from '@/components/sections/CTA'
import Company from '@/components/sections/Company'

interface Props {
  location: Location
  themeContext: ThemeContextType
}

interface State {}

class IndexPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.props.themeContext.setTheme(themes.default)
  }
  render() {
    return (
      <Root>
        <SEO location={this.props.location} />
        <KV />
        <Issues id="issues" />
        <Points id="points" />
        <Case id="case" />
        <Example id="example" />
        <Pricing id="pricing" />
        <Flow id="flow" />
        <CTA id="contact" />
        <Company id="company" />
      </Root>
    )
  }
}

const Root = styled.div``

export default withContext('themeContext', ThemeContext, IndexPage)
