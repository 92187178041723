import React, { FC, Fragment, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  GatsbyImage,
  getImage,
  withArtDirection,
  IGatsbyImageData
} from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { mq, rem } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import { Theme } from '@/utils/themes'
import ThemeContext from '@/context/Theme'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Heading from '@/components/snippets/Heading'

interface Props {
  [key: string]: any
}

interface StyleProps extends Props {
  theme: Theme
}

interface ImageProps {
  aspectratio: {
    pc: number
    sp: number
  }
}

const Flow: FC<Props> = ({ ...others }) => {
  const { theme } = useContext(ThemeContext)
  const data = useStaticQuery(graphql`
    query FlowQuery {
      pc: file(relativePath: { eq: "flow-pc.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            quality: 100
            outputPixelDensities: [1, 2, 3]
          )
        }
      }
      sp: file(relativePath: { eq: "flow-sp.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            quality: 100
            outputPixelDensities: [1, 2, 3]
          )
        }
      }
    }
  `)
  const pcImage = getImage(data.pc) as IGatsbyImageData
  const spImage = getImage(data.sp) as IGatsbyImageData
  const aspectRatio = {
    pc: (pcImage.height / pcImage.width) * 100,
    sp: (spImage.height / spImage.width) * 100
  }
  const images = withArtDirection(pcImage, [
    {
      media: mq.with(breakpoints.sm),
      image: spImage
    }
  ])
  return (
    <Root color="black.0" theme={theme} {...others}>
      <Container
        responsive={{
          default: 1,
          sm: 2
        }}
      >
        <Heading
          sub={{
            text: <Fragment>最短4日で開始</Fragment>,
            type: 'contained'
          }}
        >
          ご利用の流れ
        </Heading>
        <Image aspectratio={aspectRatio} image={images} alt="ご利用の流れ" />
      </Container>
    </Root>
  )
}

export default Flow

const Root = styled(Section)<StyleProps>``

const Image = styled(GatsbyImage)<ImageProps>`
  margin-top: ${rem(48)};
  @media ${mq.and(breakpoints.md)} {
    margin-top: ${rem(32)};
  }
  @media ${mq.and(breakpoints.sm)} {
    margin-top: ${rem(24)};
  }
  > div:first-of-type {
    padding-top: ${(props) => `${props.aspectratio.pc}% !important`};
    @media ${mq.and(breakpoints.sm)} {
      padding-top: ${(props) => `${props.aspectratio.sp}% !important`};
    }
  }
`
